import React, { useState, useEffect } from 'react';
import { Bell, Settings, Play, Pause, RotateCcw, Coffee, Brain, Book } from 'lucide-react';

const PomodoroTimer = () => {
  const [timeLeft, setTimeLeft] = useState(25 * 60);
  const [isRunning, setIsRunning] = useState(false);
  const [currentMode, setCurrentMode] = useState('pomodoro');
  const [showSettings, setShowSettings] = useState(false);
  const [sessionsCompleted, setSessionsCompleted] = useState(0);
  const [currentTask, setCurrentTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [customSettings, setCustomSettings] = useState({
    pomodoro: 25,
    shortBreak: 5,
    longBreak: 15,
    autoStartBreaks: false,
    autoStartPomodoros: false,
    longBreakInterval: 4,
  });

  const [modes, setModes] = useState({
    pomodoro: {
      name: 'Pomodoro',
      duration: customSettings.pomodoro * 60,
      color: '#e53e3e',
      hoverColor: '#c53030',
      icon: Brain,
      description: 'Focus Session',
    },
    shortBreak: {
      name: 'Short Break',
      duration: customSettings.shortBreak * 60,
      color: '#38a169',
      hoverColor: '#2f855a',
      icon: Coffee,
      description: 'Quick Rest',
    },
    longBreak: {
      name: 'Long Break',
      duration: customSettings.longBreak * 60,
      color: '#3182ce',
      hoverColor: '#2c5282',
      icon: Book,
      description: 'Extended Break',
    },
  });

  useEffect(() => {
    setModes((prev) => ({
      ...prev,
      pomodoro: { ...prev.pomodoro, duration: customSettings.pomodoro * 60 },
      shortBreak: { ...prev.shortBreak, duration: customSettings.shortBreak * 60 },
      longBreak: { ...prev.longBreak, duration: customSettings.longBreak * 60 },
    }));
  }, [customSettings]);

  useEffect(() => {
    setTimeLeft(modes[currentMode].duration);
  }, [modes, currentMode]);

  useEffect(() => {
    let interval = null;
    if (isRunning && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            handleTimerComplete();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, timeLeft]);

  const handleTimerComplete = () => {
    setIsRunning(false);
    if (currentMode === 'pomodoro') {
      setSessionsCompleted((prev) => prev + 1);
      if (customSettings.autoStartBreaks) {
        const nextMode =
          sessionsCompleted % customSettings.longBreakInterval === customSettings.longBreakInterval - 1
            ? 'longBreak'
            : 'shortBreak';
        switchMode(nextMode);
        setIsRunning(true);
      }
    } else if (customSettings.autoStartPomodoros) {
      switchMode('pomodoro');
      setIsRunning(true);
    }
    const audio = new Audio('/api/placeholder/audio');
    audio.play().catch((e) => console.log('Audio play failed:', e));
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const switchMode = (mode) => {
    setCurrentMode(mode);
    setTimeLeft(modes[mode].duration);
    setIsRunning(false);
  };

  const handleAddTask = (e) => {
    e.preventDefault();
    if (currentTask.trim()) {
      setTasks([...tasks, { text: currentTask, completed: false }]);
      setCurrentTask('');
    }
  };

  const toggleTask = (index) => {
    const newTasks = [...tasks];
    newTasks[index].completed = !newTasks[index].completed;
    setTasks(newTasks);
  };

  const SettingsModal = () => {
    const [tempSettings, setTempSettings] = useState(customSettings);

    if (!showSettings) return null;

    return (
      <div className="fixed inset-0 flex justify-center items-center p-1 z-50">
        <div className="rounded-2xl w-full max-w-md max-h-[90vh] overflow-auto p-3">
          <h2 className="text-2xl font-semibold mb-6">Timer Settings</h2>
          
          <div className="space-y-6">
            <div>
              <label className="block mb-2">
                Pomodoro (minutes)
                <input
                  type="number"
                  min="1"
                  max="60"
                  value={tempSettings.pomodoro}
                  onChange={(e) =>
                    setTempSettings({
                      ...tempSettings,
                      pomodoro: parseInt(e.target.value) || tempSettings.pomodoro,
                    })
                  }
                  className="w-full p-2 mt-1 border rounded-lg"
                />
              </label>
            </div>

            <div>
              <label className="block mb-2">
                Short Break (minutes)
                <input
                  type="number"
                  min="1"
                  max="30"
                  value={tempSettings.shortBreak}
                  onChange={(e) =>
                    setTempSettings({
                      ...tempSettings,
                      shortBreak: parseInt(e.target.value) || tempSettings.shortBreak,
                    })
                  }
                  className="w-full p-2 mt-1 border rounded-lg"
                />
              </label>
            </div>

            <div>
              <label className="block mb-2">
                Long Break (minutes)
                <input
                  type="number"
                  min="1"
                  max="60"
                  value={tempSettings.longBreak}
                  onChange={(e) =>
                    setTempSettings({
                      ...tempSettings,
                      longBreak: parseInt(e.target.value) || tempSettings.longBreak,
                    })
                  }
                  className="w-full p-2 mt-1 border rounded-lg"
                />
              </label>
            </div>

            <div>
              <label className="block mb-2">
                Long Break Interval
                <input
                  type="number"
                  min="1"
                  max="10"
                  value={tempSettings.longBreakInterval}
                  onChange={(e) =>
                    setTempSettings({
                      ...tempSettings,
                      longBreakInterval: parseInt(e.target.value) || tempSettings.longBreakInterval,
                    })
                  }
                  className="w-full p-2 mt-1 border rounded-lg"
                />
              </label>
            </div>

            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={tempSettings.autoStartBreaks}
                  onChange={(e) =>
                    setTempSettings({
                      ...tempSettings,
                      autoStartBreaks: e.target.checked,
                    })
                  }
                />
                Auto-start Breaks
              </label>
            </div>

            <div>
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  checked={tempSettings.autoStartPomodoros}
                  onChange={(e) =>
                    setTempSettings({
                      ...tempSettings,
                      autoStartPomodoros: e.target.checked,
                    })
                  }
                />
                Auto-start Pomodoros
              </label>
            </div>
          </div>

          <div className="flex justify-end gap-4 mt-8">
            <button
              onClick={() => setShowSettings(false)}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                setCustomSettings(tempSettings);
                setTimeLeft(tempSettings[currentMode] * 60);
                setIsRunning(false);
                setShowSettings(false);
              }}
              className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4 md:p-8 font-sans">
      <div className="max-w-2xl mx-auto bg-white/95 rounded-3xl shadow-xl p-6 md:p-8 backdrop-blur">
        <div className="text-center mb-8">
          <h1 className="text-3xl md:text-4xl font-bold text-gray-800 mb-2">
            Focus Flow
          </h1>
          <p className="text-sm md:text-base" style={{ color: modes[currentMode].color }}>
            {modes[currentMode].description}
          </p>
        </div>

        <div className="flex flex-wrap justify-center gap-3 mb-8">
          {Object.entries(modes).map(([key, mode]) => (
            <button
              key={key}
              onClick={() => switchMode(key)}
              className="flex items-center gap-2 px-4 py-3 rounded-xl font-semibold transition-all duration-300 flex-1 min-w-[100px] max-w-[150px] justify-center"
              style={{
                backgroundColor: currentMode === key ? mode.color : '#e2e8f0',
                color: currentMode === key ? 'white' : '#4a5568',
              }}
            >
              <mode.icon size={18} />
              <span className="hidden sm:inline">{mode.name}</span>
            </button>
          ))}
        </div>

        <div className="text-center mb-8">
          <div
            className="text-6xl md:text-7xl font-bold mb-6 font-mono"
            style={{ color: modes[currentMode].color }}
          >
            {formatTime(timeLeft)}
          </div>
          <div className="flex flex-wrap justify-center gap-4">
            <button
              onClick={() => setIsRunning(!isRunning)}
              className="flex items-center gap-2 px-6 py-3 rounded-xl font-semibold text-white transition-all duration-300 flex-1 justify-center max-w-[200px]"
              style={{ backgroundColor: modes[currentMode].color }}
            >
              {isRunning ? <Pause size={24} /> : <Play size={24} />}
              {isRunning ? 'Pause' : 'Start'}
            </button>
            <button
              onClick={() => {
                setTimeLeft(modes[currentMode].duration);
                setIsRunning(false);
              }}
              className="flex items-center gap-2 px-6 py-3 rounded-xl font-semibold bg-gray-200 text-gray-700 transition-all duration-300 flex-1 justify-center max-w-[200px]"
            >
              <RotateCcw size={24} />
              Reset
            </button>
          </div>
        </div>

        <div className="text-center mb-8">
          <p className="text-gray-700">
            Sessions Completed: <span className="font-bold">{sessionsCompleted}</span>
          </p>
        </div>

        <div className="bg-gray-50 rounded-2xl p-6 mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">
            Task List
          </h2>
          <form onSubmit={handleAddTask} className="mb-4">
            <input
              type="text"
              value={currentTask}
              onChange={(e) => setCurrentTask(e.target.value)}
              placeholder="Add a new task..."
              className="w-full px-4 py-3 rounded-xl border-2 border-gray-200 focus:border-blue-500 focus:outline-none transition-colors duration-300"
            />
          </form>

          <div className="space-y-2">
            {tasks.map((task, index) => (
              <div
                key={index}
                className="flex items-center gap-3 p-3 bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-300"
              >
                <input
                  type="checkbox"
                  checked={task.completed}
                  onChange={() => toggleTask(index)}
                  className="w-5 h-5 rounded-lg cursor-pointer"
                />
                <span
                  className={`flex-1 ${
                    task.completed ? 'line-through text-gray-400' : 'text-gray-800'
                  }`}
                >
                  {task.text}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center">
          <button
            onClick={() => setShowSettings(true)}
            className="inline-flex items-center gap-2 p-2 rounded-lg text-gray-500 hover:bg-gray-100 transition-colors duration-300"
          >
            <Settings size={24} />
            <span>Settings</span>
          </button>
        </div>

        <SettingsModal />
      </div>
    </div>
  );
};

export default PomodoroTimer;