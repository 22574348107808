import React, { memo } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  LayoutDashboard,
  AlertCircle,
  LucideUsers,
  CalendarCheck,
  LogOut,
  Menu,
  ListPlus,
  MessageSquare,
} from 'lucide-react';
import { NotificationAdd } from '@mui/icons-material';

const Sidebar = ({
  toggleDrawer,
  isDrawerOpen,
  setSelectedSection,
  handleLogout,
}) => {
  const sidebarStyle = {
    position: 'fixed',
    left: isDrawerOpen ? '0' : '-320px',
    top: 0,
    height: '100vh',
    width: '300px',
    backgroundColor: '#111827',
    transition: 'all 0.3s ease-in-out',
    padding: '20px',
    boxShadow: isDrawerOpen ? '0 0 20px rgba(0,0,0,0.3)' : 'none',
    zIndex: 1000,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    opacity: isDrawerOpen ? 1 : 0,
    visibility: isDrawerOpen ? 'visible' : 'hidden',
    transition: 'all 0.3s ease-in-out',
    zIndex: 999,
  };

  const logoStyle = {
    padding: '20px 0',
    borderBottom: '1px solid rgba(255,255,255,0.1)',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  };

  const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    margin: '4px 0',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    color: '#94A3B8',
    position: 'relative',
    overflow: 'hidden',
  };

  const activeIndicatorStyle = {
    content: '""',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '3px',
    height: '20px',
    background: 'linear-gradient(45deg, #3B82F6, #2563EB)',
    borderRadius: '0 4px 4px 0',
    opacity: 0,
    transition: 'opacity 0.2s ease',
  };

  const closeButtonStyle = {
    position: 'absolute',
    right: '20px',
    top: '20px',
    background: 'none',
    border: 'none',
    color: '#94A3B8',
    cursor: 'pointer',
    padding: '8px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease',
  };

  const MenuSection = ({
    icon: Icon,
    text,
    onClick,
    isLogout = false,
  }) => (
    <div
      onClick={onClick}
      style={{
        ...menuItemStyle,
        ':hover': {
          backgroundColor: isLogout
            ? 'rgba(239,68,68,0.1)'
            : 'rgba(59,130,246,0.1)',
          color: isLogout ? '#EF4444' : '#3B82F6',
        },
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.backgroundColor = isLogout
          ? 'rgba(239,68,68,0.1)'
          : 'rgba(59,130,246,0.1)';
        e.currentTarget.style.color = isLogout ? '#EF4444' : '#3B82F6';
        e.currentTarget.querySelector('.active-indicator').style.opacity = '1';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.backgroundColor = 'transparent';
        e.currentTarget.style.color = '#94A3B8';
        e.currentTarget.querySelector('.active-indicator').style.opacity = '0';
      }}
    >
      <div className="active-indicator" style={activeIndicatorStyle} />
      <Icon size={20} style={{ marginRight: '12px' }} />
      <span style={{ fontSize: '0.95rem', fontWeight: '500' }}>{text}</span>
    </div>
  );

  return (
    <>
      <div style={overlayStyle} onClick={toggleDrawer} />
      <div style={sidebarStyle}>
        <button onClick={toggleDrawer} style={closeButtonStyle}>
          <Menu size={24} />
        </button>

        <div style={logoStyle}>
          <div
            style={{
              background: 'linear-gradient(45deg, #3B82F6, #2563EB)',
              padding: '8px',
              borderRadius: '12px',
            }}
          >
            <LayoutDashboard size={24} color="white" />
          </div>
          <span
            style={{
              fontSize: '1.25rem',
              fontWeight: '600',
              background: 'linear-gradient(45deg, #3B82F6, #2563EB)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Admin Portal
          </span>
        </div>

        <div style={{ flex: 1 }}>
          <MenuSection
            icon={LayoutDashboard}
            text="Home"
            onClick={() => {
              setSelectedSection('home');
              toggleDrawer();
            }}
          />

          <MenuSection
            icon={CalendarCheck}
            text="Calendar"
            onClick={() => {
              setSelectedSection('calendar');
              toggleDrawer();
            }}
          />

          <MenuSection
            icon={ListPlus}
            text="Create Exam"
            onClick={() => {
              setSelectedSection('createExam');
              toggleDrawer();
            }}
          />

          <MenuSection
            icon={LucideUsers}
            text="Manage Users"
            onClick={() => {
              setSelectedSection('manageUsers');
              toggleDrawer();
            }}
          />

          <MenuSection
            icon={LayoutDashboard}
            text="Manage Exams"
            onClick={() => {
              setSelectedSection('manageExamsAndAll');
              toggleDrawer();
            }}
          />
                    <MenuSection
            icon={NotificationAdd}
            text="Logs"
            onClick={() => {
              setSelectedSection('adminLogs');
              toggleDrawer();
            }}
          />

<MenuSection
            icon={MessageSquare}
            text="Chat"
            onClick={() => {
              setSelectedSection('chat');
              toggleDrawer();
            }}
          />
        </div>

        <div
          style={{
            borderTop: '1px solid rgba(255,255,255,0.1)',
            paddingTop: '20px',
            marginTop: 'auto',
          }}
        >
          <MenuSection
            icon={LogOut}
            text="Logout"
            onClick={handleLogout}
            isLogout={true}
          />
        </div>
      </div>
    </>
  );
};

export default memo(Sidebar);