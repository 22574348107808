// AdminDashboard.jsx
import React, { useEffect, useState, useCallback, lazy, Suspense } from 'react';
import { Typography, IconButton, Toolbar, Box, Grid, Paper, Button, Fade, Menu, MenuItem, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { 
  Assignment as AssignmentIcon,
  Group as GroupIcon,
  TrendingUp as TrendingUpIcon,
  Assessment as AssessmentIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon 
} from '@mui/icons-material';
import Sidebar from './Sidebar';
import ManageExamRegistrations from './ManageExamRegistrations';
import ManageUsers from './ManageUsers';
import CreateExam from './CreateExam';
import AdminCalendar from './AdminCalendar';
import Chat from './ExamChats';
import AdminLogs from './AdminLogs';

const AdminDashboardContent = lazy(() => import('./AdminDashboardContent'));

const AdminDashboard = () => {
  const [user, setUser] = useState(null);
  const [isSidebarDrawerOpen, setIsSidebarDrawerOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState('home');
  const [totalExams, setTotalExams] = useState(0);
  const [totalRegisteredExams, setTotalRegisteredExams] = useState(0);
  const [acceptedRegistrations, setAcceptedRegistrations] = useState(0);
  const [pendingRegistrations, setPendingRegistrations] = useState(0);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [notificationAnchor, setNotificationAnchor] = useState(null);
  const navigate = useNavigate();

  // Fetch all statistics
  const fetchAllStats = useCallback(async () => {
    try {
      const examsResponse = await fetch('https://usmlechats.uc.r.appspot.com/exams');
      const examsData = await examsResponse.json();
      setTotalExams(examsData.length);

      const statsResponse = await fetch('https://usmlechats.uc.r.appspot.com/examStats');
      const statsData = await statsResponse.json();

      setTotalRegisteredExams(statsData.total);
      setAcceptedRegistrations(statsData.accepted);
      setPendingRegistrations(statsData.pending);
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }, []);

  // Fetch unread notifications
  const fetchUnreadNotifications = async () => {
    try {
      const response = await fetch('https://usmlechats.uc.r.appspot.com/notifications?unread=true');
      const data = await response.json();
      setUnreadNotifications(data);
    } catch (error) {
      console.error('Error fetching unread notifications:', error);
    }
  };

  // Mark unread notifications as read
  const markNotificationsAsRead = async () => {
    try {
      await fetch('https://usmlechats.uc.r.appspot.com/notifications/mark-read', {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
      });

      // Update unread notifications to empty after marking them as read
      setUnreadNotifications([]);
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userData = JSON.parse(localStorage.getItem('user'));
    const userType = localStorage.getItem('type');

    if (!authToken || userType !== "admin") {
      navigate('/');
    } else {
      setUser(userData);
      fetchAllStats();
      fetchUnreadNotifications(); // Fetch unread notifications on page load
    }
  }, [navigate, fetchAllStats]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('type');
    navigate('/');
  }, [navigate]);

  const toggleSidebarDrawer = useCallback(() => {
    setIsSidebarDrawerOpen((prevState) => !prevState);
  }, []);

  const handleNotificationClick = (event) => {
    setNotificationAnchor(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchor(null);
    markNotificationsAsRead(); // Mark notifications as read when dropdown is opened
  };

  const handleOpenLog = () => {
    setNotificationAnchor(null);
    setSelectedSection('adminLogs');
  };
  const StatCard = ({ title, value, icon: Icon, gradient }) => (
    <Grid item xs={12} sm={6} md={3}>
      <Paper
        elevation={3}
        sx={{
          p: 3,
          borderRadius: '24px',
          height: '100%',
          transition: 'all 0.3s ease-in-out',
          background: gradient,
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
          },
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            right: '-20px',
            transform: 'translateY(-50%)',
            opacity: 0.1,
          }}
        >
          <Icon sx={{ fontSize: 100 }} />
        </Box>
        <Typography 
          variant="h6" 
          sx={{ 
            color: 'rgba(255,255,255,0.9)',
            fontWeight: 500,
            mb: 2 
          }}
        >
          {title}
        </Typography>
        <Typography 
          variant="h3" 
          sx={{ 
            color: 'white',
            fontWeight: 600,
            textShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          {value}
        </Typography>
      </Paper>
    </Grid>
  );

  const ActionCard = ({ title, icon: Icon, onClick, gradient }) => (
    <Grid item xs={12} sm={6}>
      <Paper
        onClick={onClick}
        sx={{
          p: 3,
          borderRadius: '24px',
          cursor: 'pointer',
          background: gradient,
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          },
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: 'rgba(255,255,255,0.2)',
            borderRadius: '16px',
            p: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon sx={{ fontSize: 32, color: 'white' }} />
        </Box>
        <Typography 
          variant="h5" 
          sx={{ 
            color: 'white',
            fontWeight: 600,
            flexGrow: 1 
          }}
        >
          {title}
        </Typography>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f8fafc', p: { xs: 2, sm: 3, md: 4 } }}>
      <Toolbar sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 4,
        bgcolor: 'white',
        borderRadius: '16px',
        boxShadow: '0 2px 12px rgba(0,0,0,0.04)',
        p: 2,
      }}>
        {/* Menu Icon and Welcome Text */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebarDrawer}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Typography 
            variant="h6" 
            sx={{
              background: 'linear-gradient(135deg, #1a237e 0%, #0d47a1 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              fontWeight: 600,
            }}
          >
            Welcome, {user?.name}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton color="inherit" onClick={handleNotificationClick}>
            <Badge badgeContent={unreadNotifications.length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button 
            variant="contained" 
            onClick={handleLogout}
            sx={{
              background: 'linear-gradient(135deg, #d32f2f 0%, #c62828 100%)',
              borderRadius: '12px',
              textTransform: 'none',
              px: 3,
              '&:hover': {
                background: 'linear-gradient(135deg, #c62828 0%, #b71c1c 100%)',
              }
            }}
          >
            Logout
          </Button>
        </Box>

        {/* Notification Dropdown */}
        <Menu
          anchorEl={notificationAnchor}
          open={Boolean(notificationAnchor)}
          onClose={handleNotificationClose}
          sx={{
            mt: '10px',
            '& .MuiPaper-root': {
              borderRadius: 8,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
              minWidth: 320,
              backgroundColor: '#fff',
            },
          }}
        >
          {/* Header */}
          <div
            style={{
              padding: '12px 16px',
              borderBottom: '1px solid #f0f0f0',
              fontWeight: 600,
              color: '#202124',
              fontSize: '1rem',
            }}
          >
            Notifications
          </div>

          {/* Notifications List */}
          {unreadNotifications.length > 0 ? (
            unreadNotifications.map((notification, index) => (
              <MenuItem
                key={index}
                onClick={handleNotificationClose}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '12px 16px',
                  gap: '4px',
                  '&:hover': {
                    backgroundColor: '#f8f9fa',
                  },
                }}
              >
                <span
                  style={{
                    fontSize: '0.95rem',
                    fontWeight: 500,
                    color: '#202124',
                    lineHeight: 1.4,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {notification.message}
                </span>
                <time
                  style={{
                    fontSize: '0.8rem',
                    color: '#5f6368',
                  }}
                >
                  {new Date(notification.timestamp).toLocaleString(undefined, {
                    dateStyle: 'medium',
                    timeStyle: 'short',
                  })}
                </time>
              </MenuItem>
            ))
          ) : (
            // Empty State
            <MenuItem
              onClick={handleNotificationClose}
              sx={{
                padding: '16px',
                textAlign: 'center',
                fontSize: '0.9rem',
                color: '#5f6368',
              }}
            >
              No new notifications
            </MenuItem>
          )}

          {/* Footer */}
          {unreadNotifications.length >= 0 && (
            <div
              style={{
                padding: '12px 16px',
                borderTop: '1px solid #f0f0f0',
                textAlign: 'center',
              }}
            >
              <button
                onClick={handleOpenLog}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#1a73e8',
                  fontSize: '0.9rem',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
              >
                See all logs
              </button>
            </div>
          )}
        </Menu>
      </Toolbar>

      <Sidebar
        toggleDrawer={toggleSidebarDrawer}
        isDrawerOpen={isSidebarDrawerOpen}
        setSelectedSection={setSelectedSection}
        handleLogout={handleLogout}
      />

      <Box sx={{ mt: 4 }}>
        <Suspense fallback={<div>Loading...</div>}>
          {selectedSection === 'home' && (
            <Fade in timeout={500}>
              <Box>
                <Grid container spacing={3} sx={{ mb: 4 }}>
                  <StatCard 
                    title="Total Exams" 
                    value={totalExams}
                    icon={AssessmentIcon}
                    gradient="linear-gradient(135deg, #6366f1 0%, #4f46e5 100%)"
                  />
                  <StatCard 
                    title="Total Registrations" 
                    value={totalRegisteredExams}
                    icon={TrendingUpIcon}
                    gradient="linear-gradient(135deg, #ec4899 0%, #d946ef 100%)"
                  />
                  <StatCard 
                    title="Accepted" 
                    value={acceptedRegistrations}
                    icon={CheckCircleIcon}
                    gradient="linear-gradient(135deg, #10b981 0%, #059669 100%)"
                  />
                  <StatCard 
                    title="Pending" 
                    value={pendingRegistrations}
                    icon={ScheduleIcon}
                    gradient="linear-gradient(135deg, #f59e0b 0%, #d97706 100%)"
                  />
                </Grid>
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  <ActionCard
                    title="Manage Exams"
                    icon={AssignmentIcon}
                    onClick={() => setSelectedSection('manageExamsAndAll')}
                    gradient="linear-gradient(135deg, #3b82f6 0%, #2563eb 100%)"
                  />
                  <ActionCard
                    title="Manage Users"
                    icon={GroupIcon}
                    onClick={() => setSelectedSection('manageUsers')}
                    gradient="linear-gradient(135deg, #8b5cf6 0%, #7c3aed 100%)"
                  />
                </Grid>
              </Box>
            </Fade>
          )}
        </Suspense>

        {selectedSection === 'manageExamsAndAll' && <ManageExamRegistrations />}
        {selectedSection === 'adminLogs' && <AdminLogs />}
        {selectedSection === 'createExam' && <CreateExam />}
        {selectedSection === 'manageUsers' && <ManageUsers />}
        {selectedSection === 'calendar' && <AdminCalendar />}
        {selectedSection === 'chat' && <Chat />}
      </Box>
    </Box>
  );
};

export default AdminDashboard;
