import React, { useState } from 'react';
import { Grid, Paper, TextField, Button, Typography, Box, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff, EmailOutlined, LockOutlined, Warning } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';
import Swal from 'sweetalert2';
import axios from 'axios';


// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '40px',
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.9)',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: '#6366F1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4F46E5',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#6B7280',
    '&.Mui-focused': {
      color: '#4F46E5',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '12px',
  borderRadius: '12px',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 600,
  background: 'linear-gradient(45deg, #6366F1 30%, #818CF8 90%)',
  boxShadow: '0 4px 12px rgba(99, 102, 241, 0.2)',
  '&:hover': {
    background: 'linear-gradient(45deg, #4F46E5 30%, #6366F1 90%)',
    boxShadow: '0 6px 16px rgba(79, 70, 229, 0.3)',
  },
}));

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    // Display loading toast
    const loadingToastId = toast.loading('Signing in...');

    try {
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const userIp = ipResponse.data.ip;
      const response = await fetch('https://usmlechats.uc.r.appspot.com/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password,userIp }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast.error(errorData.error || 'Login failed', {
          id: loadingToastId, 
        });
        throw new Error(errorData.error || 'Login failed');
      }

      const data = await response.json();
      toast.success(data.message || 'Login successful', {
        id: loadingToastId, // Update the existing toast
      });

      if (data.user.type === 'blocked') {
        Swal.fire({
          icon:'warning',
          title:'Account Not Approved',
          text: 'Your account is not approved by admin. Please contact the admin.',
          confirmButtonColor: '#3085d6',
        })
        return;
      }

      const currentDate = new Date();
      const subscriptionEndDate = new Date(data.user.subscriptionEndDate);
      if (subscriptionEndDate < currentDate) {
        Swal.fire({
          icon: 'error', // Error icon for expired subscription
          title: 'Subscription Ended',
          text: 'Your subscription period has ended. Please contact the admin.',
          confirmButtonColor: '#d33', // Red confirm button for emphasis
        })
        return;
      }

      localStorage.setItem('authToken', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('type', data.user.type);
      localStorage.setItem('email', data.user.email);

      if (data.user.type === 'admin') {
        navigate('/admin-dashboard');
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(error.message, {
        id: loadingToastId, // Update the existing toast
      });
    } finally {
      // Dismiss the loading toast if not already updated
      toast.dismiss(loadingToastId);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // background: 'linear-gradient(135deg, #F3F4F6 0%, #E5E7EB 100%)',
        padding: '20px',
      }}
    >
      {/* Toast notifications */}
      <Toaster position="top-right" reverseOrder={false} />

      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <StyledPaper elevation={3}>
            <Box textAlign="center" mb={4}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  background: 'linear-gradient(45deg, #1F2937 30%, #4F46E5 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 1,
                }}
              >
                Welcome Back
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: '#6B7280' }}
              >
                Sign in to continue to your account.
              </Typography>
            </Box>

            <form onSubmit={handleLogin}>
              <StyledTextField
                label="Email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined sx={{ color: '#6366F1' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <StyledTextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 4 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined sx={{ color: '#6366F1' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ color: '#6B7280' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <StyledButton
                type="submit"
                variant="contained"
                fullWidth
              >
                Sign In
              </StyledButton>
              
              <Box mt={3} textAlign="center">
                <Typography variant="body2" sx={{ color: '#6B7280' }}>
                  Don't have an account?{' '}
                  <Button
                    sx={{
                      textTransform: 'none',
                      color: '#6366F1',
                      '&:hover': {
                        background: 'transparent',
                        color: '#4F46E5',
                      },
                    }}
                    onClick={() => navigate('/signup')}
                  >
                    Sign up
                  </Button>
                </Typography>
              </Box>
            </form>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
