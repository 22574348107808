import React, { useEffect, useState } from 'react';
import {
  Typography, Grid, Card, CardContent, Button, Dialog,
  DialogTitle, DialogContent, DialogActions, TextField,
  Skeleton, Box, MenuItem, CircularProgress, IconButton
} from '@mui/material';
import TimelineIcon from '@mui/icons-material/Timeline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import EditIcon from '@mui/icons-material/Edit';
import toast, { Toaster } from 'react-hot-toast';
import { keyframes } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { Calendar } from 'lucide-react';

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(59, 130, 246, 0); }
  100% { box-shadow: 0 0 0 0 rgba(59, 130, 246, 0); }
`;

const DashboardContent = ({ user, handleLogout }) => {
  const navigate = useNavigate();
  const [totalExams, setTotalExams] = useState(0);
  const [acceptedExams, setAcceptedExams] = useState(0);
  const [pendingExams, setPendingExams] = useState(0);
  const [registeredExams, setRegisteredExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [examDate, setExamDate] = useState('');
  const [examTime, setExamTime] = useState('');
  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState('');
  const [loadingExams, setLoadingExams] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editingExamId, setEditingExamId] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      const email = localStorage.getItem('email');
      if (!email) {
        alert('Email not found in local storage');
        return;
      }

      try {
        const response = await fetch(`https://usmlechats.uc.r.appspot.com/check-subscription?email=${email}`);
        if (!response.ok) {
          const errorData = await response.json();
          alert(errorData.error || 'Failed to check subscription status');
          return;
        }

        const data = await response.json();
        setSubscriptionEndDate(new Date(data.user.subscriptionEndDate));

        if (data.user.type === 'admin') {
          navigate('/admin-dashboard');
        }
      } catch (error) {
        console.error('Error:', error.message);
        window.location.href = '/wait';
      }
    };
    fetchSubscriptionData();
  }, [navigate]);

  useEffect(() => {
    const fetchAvailableExams = async () => {
      setLoadingExams(true);
      const email = localStorage.getItem('email');
      if (!email) {
        toast.error('Email not found in local storage');
        setLoadingExams(false);
        return;
      }

      try {
        const response = await fetch(`https://usmlechats.uc.r.appspot.com/user-exam?email=${email}`);
        if (!response.ok) {
          const errorData = await response.json();
          toast.error(errorData.error || 'Failed to fetch user exam');
          setLoadingExams(false);
          return;
        }

        const data = await response.json();
        setExams([data]);
        if (data.examId) {
          setSelectedExam(data.examId);
        }
      } catch (error) {
        console.error('Error fetching available exams:', error);
        toast.error('Failed to fetch available exams');
      } finally {
        setLoadingExams(false);
      }
    };
    fetchAvailableExams();
  }, []);

  useEffect(() => {
    if (user) {
      fetchRegisteredExams();
    }
  }, [user]);

  const fetchRegisteredExams = () => {
    setLoading(true);
    fetch(`https://usmlechats.uc.r.appspot.com/registeredExams?email=${encodeURIComponent(user.email)}`)
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));
        setRegisteredExams(sortedData);
        setTotalExams(data.length);
        setAcceptedExams(data.filter(exam => exam.status === 'Accepted').length);
        setPendingExams(data.filter(exam => exam.status === 'Pending').length);
      })
      .catch(error => {
        console.error('Error fetching registered exams:', error);
        toast.error('Failed to fetch exams');
      })
      .finally(() => setLoading(false));
  };

  const handleRegister = () => {
    if (!selectedExam || !examDate || !examTime) {
      toast.error('Please fill in all fields');
      return;
    }

    const selectedDateTime = new Date(`${examDate}T${examTime}`);
    if (!subscriptionEndDate) {
      toast.error('Subscription end date not available');
      return;
    }

    if (selectedDateTime <= new Date() || selectedDateTime > subscriptionEndDate) {
      toast.error('The selected date and time must be within your subscription period.');
      return;
    }

    const selectedExamDetails = exams.find(exam => exam.examId === selectedExam);

    const registrationData = {
      examId: selectedExamDetails.examId,
      examName: selectedExamDetails.examName,
      date: examDate,
      time: examTime,
      email: user.email
    };

    const url = editMode
      ? `https://usmlechats.uc.r.appspot.com/updateExam/${editingExamId}`
      : 'https://usmlechats.uc.r.appspot.com/registerExam';
    const method = editMode ? 'PUT' : 'POST';

    fetch(url, {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(registrationData)
    })
      .then(response => response.json())
      .then(() => {
        toast.success(editMode ? 'Exam updated successfully' : 'Exam registered successfully');
        setOpenDialog(false);
        setSelectedExam('');
        setExamDate('');
        setExamTime('');
        setEditMode(false);
        setEditingExamId(null);
        fetchRegisteredExams();
      })
      .catch(error => {
        console.error('Error registering/updating exam:', error);
        toast.error('Failed to register/update exam');
      });
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedExam('');
    setExamDate('');
    setExamTime('');
    setEditMode(false);
    setEditingExamId(null);
  };

  const handleEditExam = (exam) => {
    setSelectedExam(exam.examId);
    setExamDate(exam.date);
    setExamTime(exam.time);
    setEditMode(true);
    setEditingExamId(exam.id);
    setOpenDialog(true);
  };

  const calculateTimeLeft = (examDate) => {
    const difference = new Date(examDate) - new Date();
    if (difference <= 0) return 'Exam started';

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);

    return `${days}d ${hours}h ${minutes}m`;
  };

  const getRemainingDays = () => {
    if (!subscriptionEndDate) return 0;
    const currentDate = new Date();
    const timeDiff = subscriptionEndDate.getTime() - currentDate.getTime();
    return Math.max(0, Math.ceil(timeDiff / (1000 * 60 * 60 * 24)));
  };

  const styles = {
    container: {
      padding: '20px',
      minHeight: '100vh',
      background: 'linear-gradient(145deg, #f6f8fc 0%, #f0f4f8 100%)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '40px',
      flexWrap: 'wrap',
    },
    welcomeContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      flexWrap: 'wrap',
    },
    welcomeText: {
      fontSize: '2rem',
      fontWeight: 'bold',
      background: 'linear-gradient(90deg, #6366f1 0%, #3b82f6 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      textAlign: 'center',
    },
    statsCard: {
      borderRadius: '16px',
      transition: 'all 0.3s ease',
      height: '100%',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
    },
    examCard: {
      borderRadius: '16px',
      marginTop: '16px',
      position: 'relative',
      overflow: 'hidden',
      animation: `${pulseAnimation} 2s infinite`,
    },
    subscriptionCard: {
      maxWidth: '100%',
      background: 'linear-gradient(135deg, #EBF4FF 0%, #E6E6FF 100%)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
      },
      margin: 'auto',
    },
  };
  const isMobile = window.innerWidth < 640;

  return (
    <div style={styles.container}>
      <Toaster position="top-right" />

      <div style={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: isMobile ? 'stretch' : 'center',
      padding: '16px',
      // backgroundColor: 'white',
      borderRadius: '8px',
      margin: '16px',
      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      }}>
        <div style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          color: '#1f2937',
        }}>
          Welcome back, {user?.name || 'Student'}
        </div>
        <span style={{ 
          fontSize: '2.5rem',
          lineHeight: '1',
        }}>
          👋
        </span>
      </div>

      <button 
        onClick={handleLogout}
        style={{
          backgroundColor: '#ef4444',
          color: 'white',
          padding: '8px 16px',
          border: 'none',
          borderRadius: '6px',
          fontSize: '1rem',
          fontWeight: '500',
          cursor: 'pointer',
          width: isMobile ? '100%' : 'auto',
          marginTop: isMobile ? '16px' : '0',
          transition: 'background-color 0.2s',
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#dc2626'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#ef4444'}
      >
        Logout
      </button>
    </div>


      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ ...styles.statsCard, bgcolor: '#3b82f6' }}>
            <CardContent>
              <TimelineIcon sx={{ fontSize: 40, color: 'white', opacity: 0.3 }} />
              <Typography variant="h6" color="white">Total Exams</Typography>
              <Typography variant="h4" color="white">{totalExams}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ ...styles.statsCard, bgcolor: '#10b981' }}>
            <CardContent>
              <CheckCircleIcon sx={{ fontSize: 40, color: 'white', opacity: 0.3 }} />
              <Typography variant="h6" color="white">Accepted</Typography>
              <Typography variant="h4" color="white">{acceptedExams}</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card sx={{ ...styles.statsCard, bgcolor: '#f59e0b' }}>
            <CardContent>
              <PendingIcon sx={{ fontSize: 40, color: 'white', opacity: 0.3 }} />
              <Typography variant="h6" color="white">Pending</Typography>
              <Typography variant="h4" color="white">{pendingExams}</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Button
          variant="contained"
          onClick={() => setOpenDialog(true)}
          sx={{
            bgcolor: '#3b82f6',
            '&:hover': { bgcolor: '#2563eb' },
            width: { xs: '100%', sm: 'auto' },
          }}
        >
          Register New Exam
        </Button>

        {loading && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {[1, 2, 3].map((item) => (
              <Grid item xs={12} md={4} key={item}>
                <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 2 }} />
              </Grid>
            ))}
          </Grid>
        )}

        {!loading && (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {registeredExams.length === 0 ? (
              <Grid item xs={12}>
                <Typography variant="h6" color="text.secondary" align="center">
                  No exams registered yet
                </Typography>
              </Grid>
            ) : (
              registeredExams.map((exam) => (
                <Grid item xs={12} md={4} key={exam.id}>
                  <Card sx={styles.examCard}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {exam.examName}
                      </Typography>
                      <Typography color="text.secondary">
                        Date: {new Date(exam.date).toLocaleDateString()} <br />
                        Time: {exam.time}
                      </Typography>
                      <Typography
                        sx={{
                          color: exam.status === 'Accepted' ? '#10b981' : '#f59e0b',
                          fontWeight: 'bold',
                          mt: 1,
                        }}
                      >
                        {exam.status}
                      </Typography>
                      <Typography
                        sx={{ mt: 2, color: '#3b82f6', fontWeight: 'bold' }}
                      >
                        Time remaining: {calculateTimeLeft(exam.date)}
                      </Typography>
                      <IconButton
                        onClick={() => handleEditExam(exam)}
                        color="primary"
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                      >
                        <EditIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            borderBottom: '1px solid #e0e0e0',
            background: 'linear-gradient(to right, #3b82f6, #2563eb)',
            color: 'white',
          }}
        >
          {editMode ? 'Edit Exam Registration' : 'Register for Exam'}
        </DialogTitle>
        <DialogContent sx={{ pt: 3 }}>
          {loadingExams ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography color="text.secondary" sx={{ mb: 2 }}>
                Subscription End Date: {subscriptionEndDate?.toLocaleDateString()}
              </Typography>
              <TextField
                select
                label="Select Exam"
                value={selectedExam}
                onChange={(e) => setSelectedExam(e.target.value)}
                fullWidth
                margin="normal"
                disabled={editMode}
              >
                {exams.map((exam) => (
                  <MenuItem key={exam.examId} value={exam.examId}>
                    {exam.examName}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                margin="normal"
                label="Select Date"
                type="date"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={examDate}
                onChange={(e) => setExamDate(e.target.value)}
              />
              <TextField
                margin="normal"
                label="Select Time"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={examTime}
                onChange={(e) => setExamTime(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2, borderTop: '1px solid #e0e0e0' }}>
          <Button
            onClick={handleDialogClose}
            sx={{ color: '#64748b', '&:hover': { background: '#f1f5f9' } }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRegister}
            variant="contained"
            sx={{ bgcolor: '#3b82f6', '&:hover': { bgcolor: '#2563eb' } }}
          >
            {editMode ? 'Update' : 'Register'}
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{
      margin: '20px auto',
      maxWidth: '400px',
      width: '90%',
      padding: '20px',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
      }}>
        <Calendar className="text-indigo-600" size={32} />
        <div style={{ marginLeft: '16px' }}>
          <div style={{
            fontSize: '14px',
            color: '#666666',
          }}>
            Subscription Ends
          </div>
          <div style={{
            fontSize: '18px',
            color: '#4F46E5',
            fontWeight: '600',
          }}>
            {subscriptionEndDate?.toLocaleDateString()}
          </div>
        </div>
      </div>

      <div style={{
        marginTop: '16px',
        padding: '16px',
        backgroundColor: '#F9FAFB',
        borderRadius: '8px',
        textAlign: 'center',
      }}>
        <div style={{
          fontSize: '28px',
          color: '#4F46E5',
          fontWeight: '700',
          marginBottom: '4px',
        }}>
          {getRemainingDays()}
        </div>
        <div style={{
          fontSize: '16px',
          color: '#666666',
        }}>
          days remaining
        </div>
      </div>
    </div>
    </div>
  );
};

export default DashboardContent;