import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material';

const tableHeaderStyle = {
  backgroundColor: '#f5f5f5',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  color: '#2c3e50',
};

const AdminLogs = () => {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  // Fetch logs from the server
  useEffect(() => {
    const fetchLogs = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://usmlechats.uc.r.appspot.com/notifications');
        const data = await response.json();
        setLogs(data);
        setFilteredLogs(data); // Initialize filtered logs
      } catch (error) {
        console.error('Error fetching logs:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, []);

  // Handle search functionality
  const handleSearch = (term) => {
    setSearchTerm(term);
    if (!term) {
      setFilteredLogs(logs); // Reset to full list when search is cleared
      return;
    }

    const filtered = logs.filter((log) =>
      log.message.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredLogs(filtered);
  };

  // Handle log deletion
  const handleDeleteLog = async (id) => {
    try {
      console.log('Deleting log with ID:', id); // Confirm the `id` is correct
      const response = await fetch(`https://usmlechats.uc.r.appspot.com/notifications/${id}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete log');
      }
  
      // Remove the deleted log from the frontend state
      setLogs(logs.filter((log) => log.id !== id));
      setFilteredLogs(filteredLogs.filter((log) => log.id !== id));
    } catch (error) {
      console.error('Error deleting log:', error);
    }
  };
  
  

  return (
    <Box sx={{ backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Paper
        elevation={0}
        sx={{ padding: '24px', marginBottom: '24px', borderRadius: '12px' }}
      >
        <Typography
          variant="h5"
          sx={{ marginBottom: '20px', color: '#1a237e', fontWeight: 600 }}
        >
          Admin Logs
        </Typography>

        <Box sx={{ position: 'relative', marginBottom: '24px' }}>
          <SearchIcon
            sx={{ position: 'absolute', left: '12px', top: '12px', color: '#9e9e9e' }}
          />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search logs by message"
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '40px',
                borderRadius: '12px',
                backgroundColor: '#ffffff',
              },
            }}
          />
        </Box>

        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
          >
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '12px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={tableHeaderStyle}>Message</TableCell>
                  <TableCell sx={tableHeaderStyle}>Timestamp</TableCell>
                  <TableCell sx={tableHeaderStyle}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredLogs.length > 0 ? (
                  filteredLogs.map((log, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                    >
                      <TableCell>{log.message}</TableCell>
                      <TableCell>
                        {new Date(log.timestamp).toLocaleString(undefined, {
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        })}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteLog(log.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} align="center">
                      <Typography>No logs found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Box>
  );
};

export default AdminLogs;
