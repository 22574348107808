import React, { useEffect, useState } from 'react';
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  Mail,
  User,
  Clock,
  CheckCircle,
  RefreshCw,
  X,
  Calendar as CalendarIcon,
} from 'lucide-react';

const AdminCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewMode, setViewMode] = useState('month'); // 'month' or 'list'

  const colorScheme = {
    Accepted: {
      background: 'bg-emerald-500',
      text: 'text-white',
      lightBg: 'bg-emerald-50',
    },
    Pending: {
      background: 'bg-amber-500',
      text: 'text-white',
      lightBg: 'bg-amber-50',
    },
    Rejected: {
      background: 'bg-red-500',
      text: 'text-white',
      lightBg: 'bg-red-50',
    },
  };

  useEffect(() => {
    fetchRegistrations();
  }, []);

  const fetchRegistrations = async () => {
    try {
      setLoading(true);
      const response = await fetch('https://usmlechats.uc.r.appspot.com/examRegistrations');
      const data = await response.json();

      const examResponse = await fetch('https://usmlechats.uc.r.appspot.com/exams');
      const exams = await examResponse.json();
      const examsMap = exams.reduce((map, exam) => {
        map[exam.id] = exam.name;
        return map;
      }, {});

      const enrichedData = data.map((registration) => ({
        ...registration,
        examName: examsMap[registration.examId] || 'Unknown Exam',
      }));

      setEvents(enrichedData);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateStatus = async (status) => {
    if (!selectedEvent) return;

    try {
      await fetch(`https://usmlechats.uc.r.appspot.com/updateExamRegistration/${selectedEvent.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          status,
          email: selectedEvent.email,
          examName: selectedEvent.examName,
        }),
      });

      setSelectedEvent(null);
      fetchRegistrations();
    } catch (error) {
      console.error('Error updating registration status:', error);
    }
  };

  const handleDelete = async () => {
    if (!selectedEvent || !window.confirm('Are you sure you want to delete this registration?')) return;

    try {
      await fetch(`https://usmlechats.uc.r.appspot.com/deleteExam/${selectedEvent.id}`, { method: 'DELETE' });
      setSelectedEvent(null);
      fetchRegistrations();
    } catch (error) {
      console.error('Error deleting registration:', error);
    }
  };

  const nextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const prevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const formatDate = (date) =>
    new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

  const getDaysInMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const firstDay = new Date(year, month, 1).getDay();
    return { daysInMonth, firstDay };
  };

  const getEventsForDate = (date) =>
    events.filter((event) => new Date(event.date).toDateString() === date.toDateString());

  const generateCalendarDays = () => {
    const { daysInMonth, firstDay } = getDaysInMonth(currentDate);
    const days = [];
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    for (let i = 0; i < firstDay; i++) {
      days.push(<div key={`empty-${i}`} className="h-24 bg-gray-50 border border-gray-200" />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayEvents = getEventsForDate(date);

      days.push(
        <div key={day} className="h-24 bg-white border border-gray-200 p-1 overflow-hidden">
          <div className="text-sm font-medium mb-1">{day}</div>
          <div className="space-y-1">
            {dayEvents.map((event) => (
              <div
                key={event.id}
                onClick={() => setSelectedEvent(event)}
                className={`${
                  colorScheme[event.status]?.lightBg || 'bg-gray-50'
                } cursor-pointer rounded p-1 text-xs truncate hover:opacity-80 transition-opacity`}
              >
                {event.email}
              </div>
            ))}
          </div>
        </div>
      );
    }

    return days;
  };

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-7xl mx-auto">
        <div className="bg-white rounded-lg shadow-sm p-4 mb-4">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center gap-2">
              <Calendar className="h-6 w-6 text-indigo-600" />
              <h1 className="text-xl font-bold text-gray-900">Exam Registrations</h1>
            </div>
            <button
              onClick={() => setViewMode(viewMode === 'month' ? 'list' : 'month')}
              className="px-3 py-1.5 text-sm bg-indigo-50 text-indigo-600 rounded-md hover:bg-indigo-100"
            >
              {viewMode === 'month' ? 'List View' : 'Calendar View'}
            </button>
          </div>

          {viewMode === 'month' && (
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">
                {currentDate.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}
              </h2>
              <div className="flex gap-2">
                <button onClick={prevMonth} className="p-1 hover:bg-gray-100 rounded">
                  <ChevronLeft className="h-5 w-5" />
                </button>
                <button onClick={nextMonth} className="p-1 hover:bg-gray-100 rounded">
                  <ChevronRight className="h-5 w-5" />
                </button>
              </div>
            </div>
          )}

          {viewMode === 'month' ? (
            <div className="grid grid-cols-7 gap-px bg-gray-200">
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
                <div key={day} className="bg-gray-50 p-2 text-center text-sm font-medium">
                  {day}
                </div>
              ))}
              {generateCalendarDays()}
            </div>
          ) : (
            <div className="space-y-2">
              {events.map((event) => (
                <div
                  key={event.id}
                  onClick={() => setSelectedEvent(event)}
                  className={`${
                    colorScheme[event.status]?.lightBg || 'bg-gray-50'
                  } p-3 rounded-lg cursor-pointer hover:opacity-90 transition-opacity`}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <Mail className="h-4 w-4 text-gray-600" />
                      <span className="font-medium">{event.email}</span>
                    </div>
                    <span
                      className={`px-2 py-1 rounded-full text-xs ${colorScheme[event.status]?.background} ${colorScheme[event.status]?.text}`}
                    >
                      {event.status}
                    </span>
                  </div>
                  <div className="mt-2 text-sm text-gray-600">{formatDate(event.date)}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {selectedEvent && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-md max-h-[90vh] overflow-auto">
            <div className={`${colorScheme[selectedEvent.status]?.background || 'bg-gray-500'} p-4 rounded-t-lg`}>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold text-white">Registration Details</h3>
                <button
                  onClick={() => setSelectedEvent(null)}
                  className="text-white/80 hover:text-white"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="p-4 space-y-4">
              <div className="flex items-center gap-2">
                <User className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm text-gray-500">Email</p>
                  <p className="font-medium">{selectedEvent.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <CalendarIcon className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm text-gray-500">Exam</p>
                  <p className="font-medium">{selectedEvent.examName || 'N/A'}</p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Clock className="h-5 w-5 text-gray-500" />
                <div>
                  <p className="text-sm text-gray-500">Date</p>
                  <p className="font-medium">{formatDate(selectedEvent.date)}</p>
                </div>
              </div>
            </div>
            <div className="border-t p-4 bg-gray-50 rounded-b-lg flex flex-wrap gap-2">
              {selectedEvent.status !== 'Accepted' && (
                <button
                  onClick={() => handleUpdateStatus('Accepted')}
                  className="flex items-center gap-1 px-3 py-1.5 bg-emerald-500 text-white rounded-md hover:bg-emerald-600"
                >
                  <CheckCircle className="h-4 w-4" />
                  Approve
                </button>
              )}
              {selectedEvent.status === 'Accepted' && (
                <button
                  onClick={() => handleUpdateStatus('Pending')}
                  className="flex items-center gap-1 px-3 py-1.5 bg-amber-500 text-white rounded-md hover:bg-amber-600"
                >
                  <RefreshCw className="h-4 w-4" />
                  Mark as Pending
                </button>
              )}
              <button
                onClick={handleDelete}
                className="px-3 py-1.5 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                Delete
              </button>
              <button
                onClick={() => setSelectedEvent(null)}
                className="px-3 py-1.5 border border-gray-300 rounded-md hover:bg-gray-100"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminCalendar;
