import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Button,
  Box,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
const ManageExams = forwardRef((props, ref) => {
  const [exams, setExams] = useState([]);
  const [editingExamId, setEditingExamId] = useState(null);
  const [updatedExamName, setUpdatedExamName] = useState('');

  // Fetch exams on component mount
  useEffect(() => {
    fetchExams();
  }, []);

  // Function to fetch exams from the server
  const fetchExams = async () => {
    try {
      const response = await fetch('https://usmlechats.uc.r.appspot.com/exams');
      const data = await response.json();
      setExams(data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  // Expose the fetchExams function to the parent component
  useImperativeHandle(ref, () => ({
    refreshExams: fetchExams, // This allows the parent to call fetchExams
  }));

  // Trigger edit mode for an exam
  const handleEdit = (id, currentName) => {
    setEditingExamId(id);
    setUpdatedExamName(currentName);
  };

  // Save updated exam name
  const handleSave = async (id) => {
    if (!updatedExamName.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Validation Error',
        text: 'Exam name cannot be empty!',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    try {
      const response = await fetch(`https://usmlechats.uc.r.appspot.com/updateExamNameAdmin/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newName: updatedExamName }),
      });

      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Exam name updated successfully!',
          confirmButtonColor: '#3085d6',
        });
        setEditingExamId(null);
        setUpdatedExamName('');
        fetchExams();
      } else {
        const errorData = await response.json();
        console.error('Failed to update exam:', errorData);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to update exam. Please try again.',
          confirmButtonColor: '#d33',
        });
        
      }
    } catch (error) {
      console.error('Error updating exam name:', error);
    }
  };

  // Cancel editing
  const handleCancel = () => {
    setEditingExamId(null);
    setUpdatedExamName('');
  };

  // Delete an exam
  const handleDelete = async (id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This action will permanently delete the exam.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(`https://usmlechats.uc.r.appspot.com/deleteExamAdmin/${id}`, {
            method: 'DELETE',
          });
  
          if (response.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Deleted!',
              text: 'The exam has been deleted successfully.',
              confirmButtonColor: '#3085d6',
            });
            fetchExams(); // Refresh the exam list
          } else {
            const errorData = await response.json();
            console.error('Failed to delete exam:', errorData);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: errorData.error || 'Failed to delete the exam. Please try again.',
              confirmButtonColor: '#d33',
            });
          }
        } catch (error) {
          console.error('Error deleting exam:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An unexpected error occurred. Please try again later.',
            confirmButtonColor: '#d33',
          });
        }
      }
    });
  };
  

  return (
    <Box sx={{ p: 2, backgroundColor: '#f9fafb', borderRadius: 2 }}>
      <TableContainer component={Paper} sx={{ borderRadius: 4, boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)' }}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f1f5f9' }}>
              <TableCell sx={{ fontWeight: 600, fontSize: '1rem', color: 'text.primary' }}>Exam Name</TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  fontSize: '1rem',
                  color: 'text.primary',
                  textAlign: 'right',
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {exams.map((exam) => (
              <TableRow key={exam.id} hover sx={{ '&:hover': { backgroundColor: '#f8f9fa' } }}>
                <TableCell>
                  {editingExamId === exam.id ? (
                    <TextField
                      value={updatedExamName}
                      onChange={(e) => setUpdatedExamName(e.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 2,
                        },
                      }}
                    />
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: 500,
                        color: 'text.secondary',
                      }}
                    >
                      {exam.name}
                    </Typography>
                  )}
                </TableCell>
                <TableCell align="right">
                  {editingExamId === exam.id ? (
                    <>
                      <IconButton color="primary" onClick={() => handleSave(exam.id)}>
                        <SaveIcon />
                      </IconButton>
                      <IconButton color="error" onClick={handleCancel}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton color="primary" onClick={() => handleEdit(exam.id, exam.name)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDelete(exam.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default ManageExams;
