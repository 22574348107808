import React, { useState, useRef } from 'react';
import { Grid, Paper, Typography, TextField, Button, Box } from '@mui/material';
import ManageExams from './ManageExams';
import Swal from 'sweetalert2';


const CreateExam = () => {
  const [examName, setExamName] = useState('');
  const [isCreating, setIsCreating] = useState(false); // For loading button state

  // Reference to ManageExams for refreshing the list
  const refreshExamsRef = useRef(null);

  const handleCreateExam = () => {
    if (!examName.trim()) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Exam Name',
        text: 'Please enter an exam name before creating.',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    setIsCreating(true); // Start loading state
    fetch('https://usmlechats.uc.r.appspot.com/createExam', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ examName }),
    })
      .then((response) => response.json())
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Exam Created',
          text: data.message || 'Exam created successfully!',
          confirmButtonColor: '#3085d6',
        });
        setExamName(''); // Clear the input field

        // Trigger the refresh of the exam list in ManageExams
        if (refreshExamsRef.current) {
          refreshExamsRef.current.refreshExams();
        }
      })
      .catch((error) => {
        console.error('Error creating exam:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to create exam. Please try again.',
          confirmButtonColor: '#d33',
        });
      })
      .finally(() => setIsCreating(false)); // Stop loading state
  };

  return (
    <Box sx={{ p: { xs: 2, sm: 4 } }}>
      <Grid container spacing={4}>
        {/* Create Exam Card */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={4}
            sx={{
              padding: 4,
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                color: 'primary.main',
                mb: 1,
                textAlign: 'center',
              }}
            >
              Create New Exam
            </Typography>
            <TextField
              label="Exam Name"
              placeholder="Enter exam name"
              value={examName}
              onChange={(e) => setExamName(e.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: { borderRadius: 2 },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateExam}
              disabled={isCreating}
              sx={{
                py: 1.5,
                fontSize: '1rem',
                fontWeight: 600,
                textTransform: 'none',
                alignSelf: 'center',
                width: '50%',
                borderRadius: 50,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                '&:hover': {
                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.3)',
                },
              }}
            >
              {isCreating ? 'Creating...' : 'Create Exam'}
            </Button>
          </Paper>
        </Grid>

        {/* Manage Exams Section */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={4}
            sx={{
              padding: 4,
              borderRadius: 4,
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
              minHeight: '100%',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                color: 'primary.main',
                mb: 2,
                textAlign: 'center',
              }}
            >
              Manage Exams
            </Typography>
            <ManageExams ref={refreshExamsRef} />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateExam;
