import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  Box,
  Chip,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  CheckCircle as CheckCircleIcon,
  Block as BlockIcon,
  Search as SearchIcon
} from '@mui/icons-material';
import { differenceInDays } from 'date-fns';

const tableHeaderStyle = {
  backgroundColor: '#f5f5f5',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  color: '#2c3e50'
};

const buttonStyle = {
  borderRadius: '20px',
  textTransform: 'none',
  padding: '6px 16px'
};

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [approvalDialog, setApprovalDialog] = useState({
    open: false,
    userId: null,
    selectedExam: '',
    subscriptionEndDate: ''
  });
  const [ipDetailsDialog, setIpDetailsDialog] = useState({ open: false, data: null, loading: false });


  const fetchUsers = async () => {
    try {
      const usersResponse = await fetch('https://usmlechats.uc.r.appspot.com/users');
      const usersData = await usersResponse.json();
  
      const examsResponse = await fetch('https://usmlechats.uc.r.appspot.com/exams');
      const examsData = await examsResponse.json();
  
      // Map exams by their ID for easy lookup
      const examsMap = examsData.reduce((map, exam) => {
        map[exam.id] = exam.name;
        return map;
      }, {});
  
      // Add the examName to each user
      const enrichedUsers = usersData.map(user => ({
        ...user,
        examName: examsMap[user.examId] || 'N/A' // Default to 'N/A' if no examId matches
      }));
  
      setUsers(enrichedUsers);
    } catch (error) {
      console.error('Error fetching users or exams:', error);
    }
  };
  // fetch ip details from server
  const fetchIpDetails = async (ip) => {
    try {
      setIpDetailsDialog({ open: true, data: null, loading: true });
      const response = await fetch(`https://ipinfo.io/${ip}?token=34a576fcfd0894`);
      const data = await response.json();
      setIpDetailsDialog({ open: true, data, loading: false });
    } catch (error) {
      console.error('Error fetching IP details:', error);
      setIpDetailsDialog({ open: true, data: null, loading: false });
    }
  };
  

  const fetchExams = async () => {
    try {
      const response = await fetch('https://usmlechats.uc.r.appspot.com/exams');
      const data = await response.json();
      setExams(data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchExams();
  }, []);

  const handleApprovalDialogOpen = (userId) => {
    setApprovalDialog({
      open: true,
      userId,
      selectedExam: '',
      subscriptionEndDate: ''
    });
  };

  const handleApprovalDialogClose = () => {
    setApprovalDialog({
      open: false,
      userId: null,
      selectedExam: '',
      subscriptionEndDate: ''
    });
  };

  const calculateDaysRemaining = (endDate) => {
    if (!endDate) return 0;
    const today = new Date();
    const end = new Date(endDate);
    return differenceInDays(end, today);
  };

  const handleApprove = async () => {
    const { userId, selectedExam, subscriptionEndDate } = approvalDialog;
    if (!selectedExam || !subscriptionEndDate) {
      Swal.fire({
        icon: 'warning',
        title: 'Incomplete Selection',
        text: 'Please select both exam and subscription end date.',
        confirmButtonColor: '#3085d6',
      });
      return;
    }

    try {
      // First approve/unblock the user
      await fetch(`https://usmlechats.uc.r.appspot.com/toggleBlockUser/${userId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          type: 'approved',
          examId: selectedExam,
          subscriptionEndDate: subscriptionEndDate
        })
      });
      
      handleApprovalDialogClose();
      fetchUsers();
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const handleToggleBlock = async (id, currentStatus) => {
    if (currentStatus === 'blocked') {
      // If user is blocked, open approval dialog instead of direct unblock
      handleApprovalDialogOpen(id);
    } else {
      // If user is approved, proceed with blocking
      try {
        await fetch(`https://usmlechats.uc.r.appspot.com/toggleBlockUser/${id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ type: 'blocked' })
        });
        fetchUsers();
      } catch (error) {
        console.error('Error blocking user:', error);
      }
    }
  };


  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this user?')) return;
    try {
      await fetch(`https://usmlechats.uc.r.appspot.com/deleteUser/${id}`, {
        method: 'DELETE'
      });
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const getStatusChipColor = (type) => {
    switch (type) {
      case 'approved':
        return 'success';
      case 'pending':
        return 'warning';
      case 'blocked':
        return 'error';
      default:
        return 'default';
    }
  };

  const filteredUsers = users.filter((user) =>
    user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phone?.includes(searchTerm)
  );

  return (
    <Box sx={{backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
      <Paper elevation={0} sx={{ padding: '24px', marginBottom: '24px', borderRadius: '12px' }}>
        <Typography variant="h5" sx={{ marginBottom: '20px', color: '#1a237e', fontWeight: 600 }}>
          Manage Users
        </Typography>
        
        <Box sx={{ position: 'relative', marginBottom: '24px' }}>
          <SearchIcon sx={{ position: 'absolute', left: '12px', top: '12px', color: '#9e9e9e' }} />
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Search by Name, Email, or Phone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                paddingLeft: '40px',
                borderRadius: '12px',
                backgroundColor: '#ffffff'
              }
            }}
          />
        </Box>

        <TableContainer component={Paper} elevation={0} sx={{ borderRadius: '12px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeaderStyle}>Name</TableCell>
                <TableCell sx={tableHeaderStyle}>Email</TableCell>
                <TableCell sx={tableHeaderStyle}>Phone</TableCell>
                <TableCell sx={tableHeaderStyle}>Status</TableCell>
                <TableCell sx={tableHeaderStyle}>Subscription End</TableCell>
                <TableCell sx={tableHeaderStyle}>Days Remaining</TableCell>
                <TableCell sx={tableHeaderStyle}>Assigned Exam</TableCell>
                <TableCell sx={tableHeaderStyle}>IP</TableCell>
                <TableCell sx={tableHeaderStyle}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user.id} sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.phone}</TableCell>
                  <TableCell>
                    <Chip
                      label={user.type?.toUpperCase()}
                      color={getStatusChipColor(user.type)}
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  </TableCell>
                  <TableCell>{user.subscriptionEndDate || 'N/A'}</TableCell>
                  <TableCell>
                    {user.subscriptionEndDate && (
                      <Typography
                        sx={{
                          color: calculateDaysRemaining(user.subscriptionEndDate) > 30 ? '#2e7d32' : '#d32f2f',
                          fontWeight: 500
                        }}
                      >
                        {calculateDaysRemaining(user.subscriptionEndDate)} days
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{user.examName || 'N/A'}</TableCell>
                  <TableCell><Button
                  variant="text"
                  color="primary"
                  onClick={() => fetchIpDetails(user.ipAddress)}
                >
                  {user.ipAddress || 'N/A'}
                </Button></TableCell>
                  <TableCell>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {(user.type === 'pending' || user.type === 'blocked') && (
            <Tooltip title={user.type === 'pending' ? 'Approve User' : 'Accept User'}>
              <Button
                variant="contained"
                color="success"
                onClick={() => handleApprovalDialogOpen(user.id)}
                sx={{
                  ...buttonStyle,
                  backgroundColor: '#4caf50',
                  '&:hover': { backgroundColor: '#388e3c' }
                }}
                startIcon={<CheckCircleIcon />}
              >
                {user.type === 'pending' ? 'Approve' : 'Accept'}
              </Button>
            </Tooltip>
          )}
          {user.type !== 'blocked' && user.type !== 'pending' && (
            <Tooltip title="Block User">
              <Button
                variant="contained"
                color="error"
                onClick={() => handleToggleBlock(user.id, user.type)}
                sx={{
                  ...buttonStyle,
                  backgroundColor: '#f44336',
                  '&:hover': { backgroundColor: '#d32f2f' }
                }}
                startIcon={<BlockIcon />}
              >
                Block
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Delete User">
            <IconButton
              color="error"
              onClick={() => handleDelete(user.id)}
              sx={{ 
                backgroundColor: '#ffebee',
                '&:hover': { backgroundColor: '#ffcdd2' }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog 
        open={approvalDialog.open} 
        onClose={handleApprovalDialogClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '16px'
          }
        }}
      >
        <DialogTitle sx={{ color: '#1a237e', fontWeight: 600 }}>
          Approve User
        </DialogTitle>
        <DialogContent sx={{ width: '400px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '12px' }}>
            <FormControl fullWidth>
              <InputLabel>Select Exam</InputLabel>
              <Select
                value={approvalDialog.selectedExam}
                onChange={(e) => setApprovalDialog(prev => ({
                  ...prev,
                  selectedExam: e.target.value
                }))}
                sx={{ borderRadius: '8px' }}
              >
                {exams.map((exam) => (
                  <MenuItem key={exam.id} value={exam.id}>
                    {exam.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="date"
              label="Subscription End Date"
              fullWidth
              value={approvalDialog.subscriptionEndDate}
              onChange={(e) => setApprovalDialog(prev => ({
                ...prev,
                subscriptionEndDate: e.target.value
              }))}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ borderRadius: '8px' }}
            />

            {approvalDialog.subscriptionEndDate && (
              <Typography sx={{ color: '#2e7d32', fontWeight: 500 }}>
                Access period: {calculateDaysRemaining(approvalDialog.subscriptionEndDate)} days
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            variant="outlined"
            onClick={handleApprovalDialogClose}
            sx={{
              ...buttonStyle,
              borderColor: '#1a237e',
              color: '#1a237e',
              '&:hover': {
                borderColor: '#0d147b'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleApprove}
            sx={{
              ...buttonStyle,
              backgroundColor: '#1a237e',
              '&:hover': {
                backgroundColor: '#0d147b'
              }
            }}
          >
            Approve
          </Button>
        </DialogActions>
      </Dialog>
      {/*ip address dialog*/}
      <Dialog
  open={ipDetailsDialog.open}
  onClose={() => setIpDetailsDialog({ open: false, data: null, loading: false })}
  fullWidth
  maxWidth="sm"
  PaperProps={{
    sx: {
      borderRadius: 8,
      boxShadow: 5,
    },
  }}
>
  <DialogTitle
    sx={{
      fontSize: '1.25rem',
      fontWeight: 600,
      textAlign: 'center',
      padding: '16px 24px',
      borderBottom: '1px solid #e0e0e0',
    }}
  >
    IP Address Details
  </DialogTitle>
  <DialogContent
    sx={{
      padding: '24px',
      backgroundColor: '#fafafa',
    }}
  >
    {ipDetailsDialog.loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    ) : ipDetailsDialog.data ? (
<Box>
    <Typography variant="h6" sx={{ fontWeight: 600, textAlign: 'center', marginBottom: 2, color: '#424242' }}>
      IP Address: {ipDetailsDialog.data.ip}
    </Typography>
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
      <Typography variant="body2" sx={{ color: '#616161' }}>
        <strong>ISP/Org:</strong> {ipDetailsDialog.data.org || 'N/A'}
      </Typography>
      <Typography variant="body2" sx={{ color: '#616161' }}>
        <strong>Region:</strong> {ipDetailsDialog.data.region || 'N/A'}
      </Typography>
      <Typography variant="body2" sx={{ color: '#616161' }}>
        <strong>City:</strong> {ipDetailsDialog.data.city || 'N/A'}
      </Typography>
      <Typography variant="body2" sx={{ color: '#616161' }}>
        <strong>Postal:</strong> {ipDetailsDialog.data.postal || 'N/A'}
      </Typography>
      <Typography variant="body2" sx={{ color: '#616161' }}>
        <strong>Country:</strong> {ipDetailsDialog.data.country || 'N/A'}
      </Typography>
      <Typography variant="body2" sx={{ color: '#616161' }}>
        <strong>Time Zone:</strong> {ipDetailsDialog.data.timezone || 'N/A'}
      </Typography>

      {/* 
        The 'loc' field is a comma-separated latitude,longitude string. 
        If you want to display them separately:
      */}
      {ipDetailsDialog.data.loc && (() => {
        const [lat, lon] = ipDetailsDialog.data.loc.split(',');
        return (
          <>
            <Typography variant="body2" sx={{ color: '#616161' }}>
              <strong>Latitude:</strong> {lat}
            </Typography>
            <Typography variant="body2" sx={{ color: '#616161' }}>
              <strong>Longitude:</strong> {lon}
            </Typography>
          </>
        );
      })()}
    </Box>
  </Box>
    ) : (
      <Typography
        variant="body1"
        color="error"
        align="center"
        sx={{ marginTop: '20px', color: '#d32f2f' }}
      >
        Unable to fetch IP details.
      </Typography>
    )}
  </DialogContent>
  <DialogActions
    sx={{
      padding: '12px 24px',
      justifyContent: 'flex-end',
      borderTop: '1px solid #e0e0e0',
    }}
  >
    <Button
      onClick={() => setIpDetailsDialog({ open: false, data: null, loading: false })}
      sx={{
        textTransform: 'none',
        fontWeight: 600,
        color: '#1976d2',
        padding: '8px 16px',
        borderRadius: 4,
        '&:hover': {
          backgroundColor: '#e3f2fd',
        },
      }}
    >
      Close
    </Button>
  </DialogActions>
</Dialog>



    </Box>
  );
};

export default ManageUsers;
