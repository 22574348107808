import React, { useState } from 'react';
import { Grid, Paper, TextField, Button, Typography, Box, IconButton, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axios from 'axios';

import { 
  Visibility, 
  VisibilityOff, 
  EmailOutlined, 
  LockOutlined, 
  PersonOutline, 
  PhoneOutlined 
} from '@mui/icons-material';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: '40px',
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.15)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.9)',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: '#6366F1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4F46E5',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#6B7280',
    '&.Mui-focused': {
      color: '#4F46E5',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '12px',
  borderRadius: '12px',
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 600,
  background: 'linear-gradient(45deg, #6366F1 30%, #818CF8 90%)',
  boxShadow: '0 4px 12px rgba(99, 102, 241, 0.2)',
  '&:hover': {
    background: 'linear-gradient(45deg, #4F46E5 30%, #6366F1 90%)',
    boxShadow: '0 6px 16px rgba(79, 70, 229, 0.3)',
  },
}));

const Signup = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
  
    if (!name || !email || !password || !phone) {
      Swal.fire({
        icon: 'warning',
        title: 'Missing Information',
        text: 'All fields are required. Please fill out the form completely.',
        confirmButtonColor: '#d33',
      });
      return;
    }
  
    try {
      // Fetch user's public IP address with fallback
      const ipResponse = await axios.get('https://api.ipify.org?format=json');
      const userIp = ipResponse.data.ip;
  
      const data = { name, email, password, phone, userIp };
  
      // Post request using axios
      const response = await axios.post('https://usmlechats.uc.r.appspot.com/signup', data);
  
      Swal.fire({
        icon: 'success',
        title: 'Signup Successful',
        text: response.data.message || 'You have successfully signed up!',
        confirmButtonColor: '#3085d6',
      });
      navigate('/');
    } catch (error) {
      console.error('Signup Error:', error.response?.data || error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response?.data?.error || error.message || 'An unexpected error occurred.',
        confirmButtonColor: '#d33',
      });
    }
  };
  

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <StyledPaper elevation={3}>
            <Box textAlign="center" mb={4}>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 700,
                  background: 'linear-gradient(45deg, #1F2937 30%, #4F46E5 90%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 1,
                }}
              >
                Create Account
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: '#6B7280' }}
              >
                Join us today and get started
              </Typography>
            </Box>

            <form onSubmit={handleSignup}>
              <StyledTextField
                label="Full Name"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutline sx={{ color: '#6366F1' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <StyledTextField
                label="Email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined sx={{ color: '#6366F1' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <StyledTextField
                label="Phone"
                fullWidth
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlined sx={{ color: '#6366F1' }} />
                    </InputAdornment>
                  ),
                }}
              />

              <StyledTextField
                label="Password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 4 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined sx={{ color: '#6366F1' }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        sx={{ color: '#6B7280' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <StyledButton
                type="submit"
                variant="contained"
                fullWidth
              >
                Create Account
              </StyledButton>

              <Box mt={3} textAlign="center">
                <Typography variant="body2" sx={{ color: '#6B7280' }}>
                  Already have an account?{' '}
                  <Button
                    sx={{
                      textTransform: 'none',
                      color: '#6366F1',
                      '&:hover': {
                        background: 'transparent',
                        color: '#4F46E5',
                      },
                    }}
                    onClick={() => navigate('/')}
                  >
                    Sign in
                  </Button>
                </Typography>
              </Box>
            </form>
          </StyledPaper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Signup;
