import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import Swal from 'sweetalert2';
const ManageExamRegistrations = () => {
  const [registrations, setRegistrations] = useState([]);
  const [exams, setExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchRegistrations();
    fetchExams();
  }, []);

  const fetchRegistrations = async () => {
    try {
      const response = await fetch('https://usmlechats.uc.r.appspot.com/examRegistrations');
      const data = await response.json();
      setRegistrations(data);
    } catch (error) {
      console.error('Error fetching registrations:', error);
    }
  };

  const fetchExams = async () => {
    try {
      const response = await fetch('https://usmlechats.uc.r.appspot.com/exams');
      const data = await response.json();
      setExams(data);
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  const handleApprove = async (id, examId, email) => {
    const examName = getExamName(examId);
    try {
      await fetch(`https://usmlechats.uc.r.appspot.com/updateExamRegistration/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          status: 'Accepted',
          email,
          examName,
        }),
      });
      fetchRegistrations();
    } catch (error) {
      console.error('Error approving registration:', error);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You won’t be able to revert this action!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
  
    if (!result.isConfirmed) return;
    try {
      await fetch(`https://usmlechats.uc.r.appspot.com/deleteExamRegistration/${id}`, {
        method: 'DELETE',
      });
      fetchRegistrations();
    } catch (error) {
      console.error('Error deleting registration:', error);
    }
  };

  const getExamName = (examId) => {
    const exam = exams.find((exam) => exam.id === examId);
    return exam ? exam.name : 'Unknown Exam';
  };

  const filteredRegistrations = registrations.filter((registration) =>
    registration.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    getExamName(registration.examId).toLowerCase().includes(searchTerm.toLowerCase()) ||
    registration.date.includes(searchTerm) ||
    registration.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ p: 4, bgcolor: '#f9fafb', minHeight: '100vh' }}>
      {/* Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: 'primary.main',
          }}
        >
          Manage Exam Registrations
        </Typography>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          sx={{
            width: '300px',
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
            },
          }}
        />
      </Box>

      {/* Table */}
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: 4,
          boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        }}
      >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#f1f5f9' }}>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>Email</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>Exam Name</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>Registration Date</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>Exam Date</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>Time</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 600, color: 'text.primary', textAlign: 'right' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRegistrations.map((registration) => (
              <TableRow
                key={registration.id}
                hover
                sx={{
                  '&:hover': { backgroundColor: '#f8f9fa' },
                }}
              >
                <TableCell>{registration.email}</TableCell>
                <TableCell>{getExamName(registration.examId)}</TableCell>
                <TableCell>
                  {new Date(registration.registeredAt).toLocaleDateString()}
                </TableCell>
                <TableCell>{registration.date}</TableCell>
                <TableCell>{registration.time}</TableCell>
                <TableCell>
                  <Typography
                    variant="body2"
                    sx={{
                      color:
                        registration.status === 'Accepted'
                          ? 'success.main'
                          : registration.status === 'Pending'
                          ? 'warning.main'
                          : 'error.main',
                      fontWeight: 500,
                    }}
                  >
                    {registration.status}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {registration.status === 'Pending' && (
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() =>
                        handleApprove(registration.id, registration.examId, registration.email)
                      }
                      sx={{
                        textTransform: 'none',
                        borderRadius: 4,
                        mr: 1,
                      }}
                    >
                      Approve
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    size="small"
                    color="error"
                    onClick={() => handleDelete(registration.id)}
                    sx={{
                      textTransform: 'none',
                      borderRadius: 4,
                    }}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ManageExamRegistrations;
