// RouteComponent.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import StudentDashboard from './StudentDashboard';
import Login from './Login';
import Signup from './Signup';
import AdminDashboard from './components/admins/AdminDashboard';
import Wait from './Wait'

const RouteComponent = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/dashboard" element={<StudentDashboard />} />
      <Route path="/admin-dashboard" element={<AdminDashboard />} />
      <Route path="/wait" element={<Wait />} />
    </Routes>
  );
};

export default RouteComponent;
