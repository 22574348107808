import React, { useState, useEffect } from 'react';
import {
  Card, CardContent, Typography, Button, Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, IconButton, Grid, Box, Fade
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import toast, { Toaster } from 'react-hot-toast';

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s ease-in-out',
  background: 'rgba(255, 255, 255, 0.9)',
  backdropFilter: 'blur(10px)',
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.12)',
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '12px',
  padding: '10px 24px',
  textTransform: 'none',
  fontWeight: 600,
  background: 'linear-gradient(45deg, #6366F1 30%, #818CF8 90%)',
  '&:hover': {
    background: 'linear-gradient(45deg, #4F46E5 30%, #6366F1 90%)',
  }
}));

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      borderColor: '#E5E7EB',
    },
    '&:hover fieldset': {
      borderColor: '#6366F1',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#4F46E5',
    },
  },
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: '16px',
    background: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(10px)',
  }
}));

const TodoDashboard = () => {
  const [todos, setTodos] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTodo, setCurrentTodo] = useState({ id: null, title: '', description: '' });
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const userEmail = localStorage.getItem('email');

  useEffect(() => {
    fetchTodos();
    // eslint-disable-next-line
  }, []);

  const fetchTodos = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://usmlechats.uc.r.appspot.com/todos?email=${encodeURIComponent(userEmail)}`);
      if (!response.ok) throw new Error('Failed to fetch to-do items');
      const data = await response.json();
      setTodos(data);
    } catch (error) {
      console.error('Error fetching to-do items:', error);
      toast.error('Failed to fetch to-do items');
    } finally {
      setLoading(false);
    }
  };

  const handleDialogOpen = (todo = { id: null, title: '', description: '' }) => {
    setCurrentTodo(todo);
    setEditMode(!!todo.id);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentTodo({ id: null, title: '', description: '' });
    setEditMode(false);
  };

  const handleSaveTodo = async () => {
    if (!currentTodo.title) {
      toast.error('Title is required');
      return;
    }

    try {
      if (editMode) {
        const response = await fetch(`https://usmlechats.uc.r.appspot.com/todos/${currentTodo.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            title: currentTodo.title,
            description: currentTodo.description,
          }),
        });
        if (!response.ok) throw new Error('Failed to update to-do item');
        toast.success('To-Do item updated successfully');
      } else {
        const response = await fetch('https://usmlechats.uc.r.appspot.com/todos', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: userEmail,
            title: currentTodo.title,
            description: currentTodo.description,
          }),
        });
        if (!response.ok) throw new Error('Failed to create to-do item');
        toast.success('To-Do item created successfully');
      }
      fetchTodos();
      handleDialogClose();
    } catch (error) {
      console.error('Error saving to-do item:', error);
      toast.error(error.message);
    }
  };

  const handleDeleteTodo = async (id) => {
    try {
      const response = await fetch(`https://usmlechats.uc.r.appspot.com/todos/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete to-do item');
      toast.success('To-Do item deleted successfully');
      fetchTodos();
    } catch (error) {
      console.error('Error deleting to-do item:', error);
      toast.error(error.message);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        padding: '40px 20px',
        background: 'linear-gradient(135deg, #F3F4F6 0%, #E5E7EB 100%)',
      }}
    >
      <Toaster 
        position="top-right"
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        }}
      />
      
      <Box maxWidth="1200px" margin="0 auto">
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 4,
            flexWrap: 'wrap',
            gap: 2
          }}
        >
          <Typography 
            variant="h4" 
            sx={{
              fontWeight: 700,
              background: 'linear-gradient(45deg, #1F2937 30%, #4F46E5 90%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            My To-Do List
          </Typography>

          <StyledButton
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => handleDialogOpen()}
          >
            Add New To-Do
          </StyledButton>
        </Box>

        <Fade in={true} timeout={800}>
          <Grid container spacing={3}>
            {loading ? (
              [1, 2, 3].map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item}>
                  <StyledCard>
                    <CardContent>
                      <Skeleton variant="text" width="60%" height={32} sx={{ mb: 1 }} />
                      <Skeleton variant="text" width="80%" height={24} sx={{ mb: 1 }} />
                      <Skeleton variant="text" width="40%" height={24} />
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Skeleton variant="circular" width={40} height={40} sx={{ mr: 1 }} />
                        <Skeleton variant="circular" width={40} height={40} />
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))
            ) : todos.length === 0 ? (
              <Grid item xs={12}>
                <Box
                  sx={{
                    textAlign: 'center',
                    py: 8,
                    background: 'rgba(255, 255, 255, 0.5)',
                    borderRadius: '16px',
                  }}
                >
                  <CheckCircleIcon sx={{ fontSize: 60, color: '#6366F1', mb: 2 }} />
                  <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
                    No to-do items yet
                  </Typography>
                  <StyledButton
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => handleDialogOpen()}
                    size="large"
                  >
                    Create Your First Todo
                  </StyledButton>
                </Box>
              </Grid>
            ) : (
              todos.map((todo) => (
                <Grid item xs={12} sm={6} md={4} key={todo.id}>
                  <StyledCard>
                    <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mb: 2,
                          fontWeight: 600,
                          color: '#1F2937'
                        }}
                      >
                        {todo.title}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          flex: 1,
                          color: '#4B5563',
                          mb: 2
                        }}
                      >
                        {todo.description}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                        <IconButton 
                          onClick={() => handleDialogOpen(todo)}
                          sx={{ 
                            color: '#6366F1',
                            '&:hover': { 
                              background: 'rgba(99, 102, 241, 0.1)',
                              transform: 'scale(1.1)'
                            }
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleDeleteTodo(todo.id)}
                          sx={{ 
                            color: '#EF4444',
                            '&:hover': { 
                              background: 'rgba(239, 68, 68, 0.1)',
                              transform: 'scale(1.1)'
                            }
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))
            )}
          </Grid>
        </Fade>
      </Box>

      <StyledDialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          pb: 1,
          fontWeight: 600,
          color: '#1F2937'
        }}>
          {editMode ? 'Edit To-Do' : 'Add New To-Do'}
        </DialogTitle>
        <DialogContent>
          <StyledTextField
            autoFocus
            margin="dense"
            label="Title"
            type="text"
            fullWidth
            value={currentTodo.title}
            onChange={(e) => setCurrentTodo({ ...currentTodo, title: e.target.value })}
            sx={{ mb: 2 }}
          />
          <StyledTextField
            margin="dense"
            label="Description"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={currentTodo.description}
            onChange={(e) => setCurrentTodo({ ...currentTodo, description: e.target.value })}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button 
            onClick={handleDialogClose}
            sx={{ 
              color: '#6B7280',
              '&:hover': { background: '#F3F4F6' }
            }}
          >
            Cancel
          </Button>
          <StyledButton onClick={handleSaveTodo} variant="contained">
            {editMode ? 'Update' : 'Save'}
          </StyledButton>
        </DialogActions>
      </StyledDialog>
    </Box>
  );
};

export default TodoDashboard;
