// StudentDashboard.js
import React, { useEffect, useState } from 'react';
import { Typography, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import Sidebar from './components/students/Sidebar';
import DashboardContent from './components/students/DashboardContent';
import TodoDashboard from './components/students/TodoDashboard.jsx';
import ExamChats from './components/students/ExamChats.jsx';

import PomodoroTimer from './components/students/Promodoro.jsx';

const StudentDashboard = () => {
  const [user, setUser] = useState(null);
  const [isSidebarDrawerOpen, setIsSidebarDrawerOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState('dashboard');
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    const userData = JSON.parse(localStorage.getItem('user'));

    if (!authToken) {
      navigate('/');
    } else {
      setUser(userData);
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    navigate('/');
  };

  const toggleSidebarDrawer = () => {
    setIsSidebarDrawerOpen(!isSidebarDrawerOpen);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {/* Toolbar */}
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={toggleSidebarDrawer} edge="start" sx={{ mr: 2 }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div">
          Student Dashboard
        </Typography>
      </Toolbar>

      {/* Sidebar */}
      <Sidebar
        toggleDrawer={toggleSidebarDrawer}
        isDrawerOpen={isSidebarDrawerOpen}
        setSelectedSection={setSelectedSection}
        handleLogout={handleLogout}
      />

      {/* Main Content */}
      {selectedSection === 'dashboard' && <DashboardContent user={user} handleLogout={handleLogout} />}
      {selectedSection === 'todo' && <TodoDashboard />} {/* Render To-Do Dashboard here */}
      {selectedSection === 'chat' && <ExamChats />}
      {selectedSection === 'pro' && <PomodoroTimer />}
    
    </div>
  );
};

export default StudentDashboard;
