import React, { useState, useEffect } from 'react';
import { Send, Trash2, ArrowLeft } from 'lucide-react';

const ExamChats = () => {
  const [examChats, setExamChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showChatList, setShowChatList] = useState(true);
  const [hoveredMessageIndex, setHoveredMessageIndex] = useState(null);
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    const storedEmail = localStorage.getItem('email');
    if (storedEmail) {
      setEmail(storedEmail);
      fetchExamChats(storedEmail);
    }

    const interval = setInterval(() => {
      if (storedEmail) {
        fetchExamChats(storedEmail);
        if (selectedChat) {
          fetchChatMessages(selectedChat.id, storedEmail);
        }
      }
    }, 5000);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(interval);
    };
  }, [selectedChat]);

  const fetchExamChats = async (userEmail) => {
    try {
      const response = await fetch(`https://usmlechats.uc.r.appspot.com/examChats?email=${userEmail}`);
      const data = await response.json();
      setExamChats(data);
    } catch (error) {
      console.error('Error fetching exam chats:', error);
    }
  };

  const handleChatSelect = async (chat) => {
    setSelectedChat(chat);
    if (isMobile) {
      setShowChatList(false);
    }
    await fetchChatMessages(chat.id, email);
  };

  const handleBackToList = () => {
    setShowChatList(true);
    setSelectedChat(null);
  };

  const fetchChatMessages = async (examId, userEmail) => {
    try {
      const response = await fetch(`https://usmlechats.uc.r.appspot.com/examChatMessages/${examId}?email=${userEmail}`);
      const messages = await response.json();
      setChatMessages(messages || []);
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = async () => {
    if (message.trim() !== '') {
      try {
        await fetch(`https://usmlechats.uc.r.appspot.com/sendMessage`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            examId: selectedChat.id,
            email,
            message,
          }),
        });
        setMessage('');
        await fetchChatMessages(selectedChat.id, email);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    }
  };

  const handleClearMessages = async () => {
    if (selectedChat) {
      try {
        await fetch(`https://usmlechats.uc.r.appspot.com/clearMessages/${selectedChat.id}`, {
          method: 'DELETE',
        });
        setChatMessages([]);
      } catch (error) {
        console.error('Error clearing messages:', error);
      }
    }
  };

  const handleDeleteMessage = async (messageIndex) => {
    if (selectedChat) {
      try {
        const updatedMessages = [...chatMessages];
        updatedMessages.splice(messageIndex, 1);

        await fetch(`https://usmlechats.uc.r.appspot.com/deleteMessage/${selectedChat.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ updatedMessages }),
        });

        setChatMessages(updatedMessages);
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    }
    setSelectedMessageIndex(null);
  };

  const getAvatarColor = (email) => {
    const colors = [
      'linear-gradient(135deg, #00C6FF, #0072FF)',
      'linear-gradient(135deg, #FF6B6B, #FF3366)',
      'linear-gradient(135deg, #4CAF50, #45B649)',
      'linear-gradient(135deg, #9C27B0, #673AB7)',
      'linear-gradient(135deg, #FF9800, #FF5722)',
      'linear-gradient(135deg, #2196F3, #1976D2)',
    ];
    const index = email.charCodeAt(0) % colors.length;
    return colors[index];
  };

  const getAvatarText = (email) => {
    return email ? email.charAt(0).toUpperCase() : '';
  };

  const handleMessageClick = (index) => {
    if (isMobile) {
      setSelectedMessageIndex(selectedMessageIndex === index ? null : index);
    }
  };

  return (
    <div style={{ display: 'flex', gap: '20px', height: isMobile ? '70vh' : '80vh', background: 'linear-gradient(135deg, #f5f7fa, #e3e6e8)', position: 'relative' }}>
      <div style={{
        width: isMobile ? '100%' : '300px',
        display: isMobile && !showChatList ? 'none' : 'block',
        background: 'white',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: '100%',
        overflow: 'hidden',
      }}>
        <div style={{
          padding: '20px',
          borderBottom: '1px solid #eee',
          background: 'white',
        }}>
          <h2 style={{ margin: 0, color: '#1a1a1a', fontSize: '1.25rem' }}>Exam Chats</h2>
        </div>

        <div style={{ overflowY: 'auto', height: 'calc(100% - 70px)' }}>
          {examChats.map((chat) => (
            <div
              key={chat.id}
              onClick={() => handleChatSelect(chat)}
              style={{
                padding: '15px 20px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                background: selectedChat?.id === chat.id ? '#f0f7ff' : 'white',
                transition: 'background 0.3s',
                borderBottom: '1px solid #eee',
              }}
            >
              <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                background: getAvatarColor(chat.id),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontWeight: 'bold',
                marginRight: '12px',
              }}>
                {getAvatarText(chat.id)}
              </div>
              <span style={{ color: '#1a1a1a', fontWeight: '500' }}>{chat.id}</span>
            </div>
          ))}
        </div>
      </div>

      {(selectedChat || !isMobile) ? (
        <div style={{
          flex: 1,
          display: isMobile && showChatList ? 'none' : 'flex',
          flexDirection: 'column',
          background: 'white',
          borderRadius: '12px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          height: '100%',
          overflow: 'hidden',
        }}>
          <div style={{
            padding: '20px',
            borderBottom: '1px solid #eee',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              {isMobile && (
                <button 
                  onClick={handleBackToList}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '5px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <ArrowLeft size={24} />
                </button>
              )}
              <h2 style={{ margin: 0, color: '#1a1a1a', fontSize: '1.25rem' }}>
                {selectedChat ? `Chat with ${selectedChat.id}` : 'Select a chat'}
              </h2>
            </div>
            <button onClick={handleClearMessages} style={{
              background: 'red',
              color: 'white',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}>
              Clear Chat
            </button>
          </div>

          <div style={{
            flex: 1,
            overflowY: 'auto',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}>
            {Array.isArray(chatMessages) && chatMessages.length > 0 ? (
              chatMessages.map((msg, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: msg.sender === email ? 'flex-end' : 'flex-start',
                    marginBottom: '10px',
                    position: 'relative',
                    paddingRight: '40px',
                    paddingLeft: '40px',
                  }}
                  onClick={() => handleMessageClick(index)}
                  onMouseEnter={() => !isMobile && setHoveredMessageIndex(index)}
                  onMouseLeave={() => !isMobile && setHoveredMessageIndex(null)}
                >
                  <div style={{
                    maxWidth: '70%',
                    padding: '15px',
                    borderRadius: '20px',
                    background: msg.sender === email ? 'linear-gradient(135deg, #00B2FF, #0085FF)' : '#d4d4d4',
                    color: msg.sender === email ? 'white' : 'black',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  }}>
                    {msg.sender !== email && (
                      <p style={{
                        margin: 0,
                        fontSize: '0.8rem',
                        color: '#555',
                        marginBottom: '5px',
                      }}>
                        {msg.sender}
                      </p>
                    )}
                    <p>{msg.message}</p>
                  </div>
                  {((isMobile && selectedMessageIndex === index) || (!isMobile && hoveredMessageIndex === index)) && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMessage(index);
                      }}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        [msg.sender === email ? 'right' : 'left']: '8px',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                        padding: '4px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Trash2 color="red" size={18} />
                    </button>
                  )}
                </div>
              ))
            ) : (
              <div>No messages available.</div>
            )}
          </div>

          <div style={{
            padding: '20px',
            borderTop: '1px solid #eee',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            position: isMobile ? 'sticky' : 'relative',
            bottom: 0,
            background: 'white',
          }}>
            <input
              type="text"
              value={message}
              onChange={handleMessageChange}
              placeholder="Type a message..."
              style={{
                flex: 1,
                padding: '10px 15px',
                borderRadius: '20px',
                border: '1px solid #ccc',
                outline: 'none',
              }}
            />
            <button
              onClick={handleSendMessage}
              style={{
                background: 'linear-gradient(135deg, #00B2FF, #0085FF)',
                border: 'none',
                borderRadius: '50%',
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              <Send color="white" size={20} />
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ExamChats;